@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/cc8d1354e7980d44-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/2d97bba81eacd14f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/b70f2b20cb57f064-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/87c613c3f688b0a2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/225bca0c9dbe1051-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/79439235270e42e9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/225bca0c9dbe1051-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/f9a101e4ac5cfd47-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/d769a6997fbe81df-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/d91dc2a87a6d444f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/eeb32184ed87fee7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/26e5571b7c0b5aee-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__garet_6953a5';
src: url(/_next/static/media/dfce331b6d7b1f66-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: '__garet_Fallback_6953a5';src: local("Arial");ascent-override: 82.51%;descent-override: 29.73%;line-gap-override: 0.00%;size-adjust: 116.93%
}.__className_6953a5 {font-family: '__garet_6953a5', '__garet_Fallback_6953a5'
}.__variable_6953a5 {--font-garet: '__garet_6953a5', '__garet_Fallback_6953a5'
}

@font-face {
font-family: '__museo_71ce5c';
src: url(/_next/static/media/5224342eb5ac1e7f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__museo_71ce5c';
src: url(/_next/static/media/5224342eb5ac1e7f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__museo_71ce5c';
src: url(/_next/static/media/231c03a7cefe9926-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__museo_71ce5c';
src: url(/_next/static/media/009fc46a1b7ff863-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__museo_Fallback_71ce5c';src: local("Arial");ascent-override: 84.94%;descent-override: 24.66%;line-gap-override: 0.00%;size-adjust: 103.94%
}.__className_71ce5c {font-family: '__museo_71ce5c', '__museo_Fallback_71ce5c'
}.__variable_71ce5c {--font-museo: '__museo_71ce5c', '__museo_Fallback_71ce5c'
}

